<template>
  <div class="main">
    <div class="app-wrapper">
      <div class="machine_title">{{ $t("Machine.Equipment") }}</div>
      <div class="search_wrapper">
        <div class="search_item">
          <div class="search_title">{{ $t("yudata.equipmennt_id") }}</div>
          <div class="search_selected">
            <el-input
              :label="$t('AddMachine.GroupSimple')"
              v-model.trim="searchForm.number"
              :placeholder="$t('AddMachine.inputSerialNumber')"
              clearable
              size="small"
            />
          </div>
        </div>
        <div class="search_item">
          <div class="search_title">{{ $t("yudata.hardSerialNum") }}</div>
          <div class="search_selected">
            <el-input
              :label="$t('AddMachine.GroupSimple')"
              v-model.trim="searchForm.serial_number"
              :placeholder="$t('yudata.inputHardwareNumber')"
              clearable
              size="small"
            />
          </div>
        </div>
        <div class="function_button_wrapper">
          <el-button type="primary" round @click="handleSearch">
            {{ $t("Machine.Search") }}</el-button
          >
          <el-button type="primary" round @click="handleReset">{{
            $t("Warning.Reset")
          }}</el-button>
        </div>
        <div
          class="function_button_wrapper"
          style="margin-left: auto; margin-right: 40px"
        >
          <el-button round type="primary" @click="handleBatchAdd">
            批量新增
          </el-button>
        </div>
      </div>
      <div class="table_content">
        <el-table
          ref="multipleTable"
          :data="tableData"
          @row-click="rowClick"
          tooltip-effect="dark"
          :header-cell-style="{ background: '#dde0e8' }"
          highlight-current-row
          row-key="id"
          :expand-row-keys="expandRowKeys"
          style="width: 100%"
          @expand-change="expandChange"
        >
          <el-table-column width="10" align="center" />
          <el-table-column type="expand" min-width="10%">
            <template slot-scope="props">
              <el-table
                ref="multipleTable"
                :data="props.row.devices"
                tooltip-effect="dark"
                style="width: 100%"
                :header-cell-style="{ background: '#dde0e8' }"
                v-loading="props.row.loading"
                element-loading-background="transparent"
              >
                <el-table-column width="70" align="center" />
                <el-table-column
                  :label="$t('Custom.Order')"
                  min-width="10%"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    <div>
                      {{
                        (props.row.page - 1) * props.row.pagesize +
                        scope.$index +
                        1
                      }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('yudata.equipmennt_id')"
                  min-width="20%"
                >
                  <template slot-scope="{ row }">
                    <div class="table_equip">
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="row.number"
                        placement="top"
                      >
                        <span>{{ row.number }}</span>
                      </el-tooltip>

                      <img
                        style="width: 20px"
                        class="copy-button"
                        src="../../assets//image/copy.png"
                        alt=""
                        srcset=""
                        @click="handleCopy(row.number)"
                      />
                    </div>
                  </template>
                </el-table-column>
                <!-- show-overflow-tooltip -->
                <el-table-column
                  :label="$t('yudata.hardSerialNum')"
                  min-width="20%"
                >
                  <template slot-scope="{ row }">
                    <div class="table_equip">
                      <span>{{ row.serial_number }}</span>
                      <img
                        style="width: 20px"
                        class="copy-button"
                        src="../../assets//image/copy.png"
                        alt=""
                        srcset=""
                        @click="handleCopy(row.serial_number)"
                      />
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('yudata.mqtt_account')"
                  min-width="20%"
                  show-overflow-tooltip
                >
                  <template slot-scope="{ row }">
                    <div class="table_equip">
                      <span>{{ row.account }}</span>
                      <img
                        style="width: 20px"
                        class="copy-button"
                        src="../../assets//image/copy.png"
                        alt=""
                        srcset=""
                        @click="handleCopy(row.account)"
                      />
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('yudata.mqtt_password')"
                  min-width="20%"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    <div class="password_wrapper">
                      <span>
                        {{ handlePasswordStatus(scope.row) }}
                      </span>
                      <img
                        :draggable="false"
                        :src="
                          scope.row.passwordVisible
                            ? require('../../assets/image/password_open.png')
                            : require('../../assets/image/password_close.png')
                        "
                        alt=""
                        @click="togglePasswordStatus(scope.row)"
                      />
                    </div>
                  </template>
                </el-table-column>

                <el-table-column
                  min-width="20%"
                  :label="$t('Setting.CreationTime')"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    <div>{{ handleTime(scope.row.create_time) }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="设备支持的模块"
                  width="200"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    <span>{{ getConnectWays(scope.row) }}</span>
                  </template>
                </el-table-column>

                <el-table-column label="云端服务" width="150">
                  <template slot-scope="scope">
                    <el-checkbox
                      class="custom_checkbox"
                      v-model="scope.row.auth_status"
                      :true-label="1"
                      :false-label="0"
                      @change="(val) => handleAuth(scope.row, val)"
                    ></el-checkbox>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('yudata.remark')"
                  min-width="20%"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    <div>{{ scope.row.remark }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('AddMachine.Operation')"
                  width="180"
                >
                  <template slot-scope="scope">
                    <div class="opearate_imgwrapper">
                      <img
                        src="../../assets/image/convert.png"
                        @click="handleConvert(scope.row, props.row.id)"
                      />
                      <img
                        src="../../assets/image/bianji_main@2x.png"
                        @click="handleEditRemark(scope.row, props.row)"
                      />
                      <img
                        src="../../assets/image/shanchu_mian@2x.png"
                        @click="handleOpenDeleteConfirmDialog(scope.row, props)"
                      />
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <div style="padding: 10px 20px; background-color: #ebecee">
                <el-pagination
                  background
                  layout="prev, pager, next, sizes"
                  :page-size="props.row.pagesize"
                  :page-sizes="[5, 10, 15]"
                  :current-page.sync="props.row.page"
                  :total="props.row.total"
                  v-show="!searchFlag"
                  @size-change="
                    (pagesize) => handleSecondSizeChange(pagesize, props.row)
                  "
                  @current-change="
                    (page) => handleSecondPageChange(page, props.row)
                  "
                />
              </div>
            </template>
          </el-table-column>
          <el-table-column
            min-width="15%"
            :label="$t('yudata.company')"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div>{{ scope.row.company }}</div>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('AddMachine.Operation')"
            width="180"
            align="center"
          >
            <template slot-scope="scope">
              <div style="display: flex; gap: 20px; align-items: center">
                <el-button
                  type="primary"
                  round
                  data-value="operate"
                  @click="handleExport(scope.row)"
                  size="mini"
                  :disabled="!expandRowKeys.includes(scope.row.id)"
                >
                  <span data-value="operate">导出</span></el-button
                >
                <img
                  data-value="operate"
                  src="../../assets/image/add.png"
                  @click="handleAddEquipId(scope.row)"
                  class="OperationImg"
                />
              </div>
            </template>
          </el-table-column>

          <el-table-column width="20" align="center" />
        </el-table>
      </div>
      <div class="pagination-container">
        <el-pagination
          background
          layout="prev, pager, next, sizes"
          :page-size="pagesize"
          :page-sizes="[5, 10, 15]"
          :current-page.sync="page"
          :total="total"
          v-show="total && total > 0"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <el-dialog
      :title="$t('yudata.equipmennt_id')"
      :visible.sync="dialogVisible"
      width="604px"
      class="dialog"
      top="30vh"
    >
      <el-form
        ref="equipmenntIdFormRef"
        :model="equipmenntIdForm"
        :rules="equipmenntIdFormRules"
        label-width="0px"
      >
        <div class="form-box">
          <el-form-item prop="number">
            <div class="label" style="text-align: left">
              {{ $t("yudata.equipmennt_id") }}
            </div>
            <div style="display: flex; gap: 20px">
              <el-input
                v-model.trim="equipmenntIdForm.number"
                :placeholder="$t('yudata.input_equipment_id')"
                auto-complete="off"
                clearable
              />
              <el-button type="primary" round @click="handleOpenCustomDialog">{{
                $t("yudata.auto_generate")
              }}</el-button>
              <el-dialog
                class="dialog"
                width="604px"
                :title="$t('yudata.generateEquipId')"
                :visible.sync="generateDialogVisible"
                append-to-body
                :close-on-click-modal="false"
              >
                <el-form
                  ref="autoGenerateRef"
                  :model="generateEquipmentIdForm"
                  label-width="0px"
                  v-loading="generateDialogLoading"
                  :rules="generateEquipmentIdFormRules"
                >
                  <el-form-item prop="customer_id">
                    <div class="label" style="text-align: left">
                      {{ $t("yudata.company") }}
                    </div>
                    <div>
                      <el-select
                        style="width: 100%"
                        v-model="generateEquipmentIdForm.customer_id"
                        :placeholder="$t('yudata.companyTip')"
                        clearable
                      >
                        <el-option
                          v-for="item in companyOptions"
                          :key="item.text"
                          :label="item.text"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </el-form-item>
                  <el-form-item prop="order_type">
                    <div class="label" style="text-align: left">
                      {{ $t("yudata.order_type") }}
                    </div>
                    <div>
                      <el-select
                        style="width: 100%"
                        v-model="generateEquipmentIdForm.order_type"
                        :placeholder="$t('yudata.orderTip')"
                        clearable
                      >
                        <el-option
                          v-for="item in orderTypeOptions"
                          :key="item.text"
                          :label="item.text"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </el-form-item>
                  <el-form-item prop="erp_type">
                    <div class="label" style="text-align: left">
                      {{ $t("yudata.erp_type") }}
                    </div>
                    <div>
                      <el-select
                        style="width: 100%"
                        v-model="generateEquipmentIdForm.erp_type"
                        :placeholder="$t('yudata.erpTip')"
                        clearable
                      >
                        <el-option
                          v-for="item in erpTypeOptions"
                          :key="item.text"
                          :label="item.text"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </el-form-item>
                  <el-form-item prop="device_type">
                    <div class="label" style="text-align: left">
                      {{ $t("Setting.EquipmentType") }}
                    </div>
                    <div>
                      <el-select
                        style="width: 100%"
                        v-model="generateEquipmentIdForm.device_type"
                        :placeholder="$t('yudata.equipTip')"
                        clearable
                      >
                        <el-option
                          v-for="item in deviceOptions"
                          :key="item.text"
                          :label="item.text"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </el-form-item>
                  <div style="display: flex; gap: 20px">
                    <el-form-item prop="hardware_large_version" style="flex: 1">
                      <div class="label" style="text-align: left">
                        {{ $t("yudata.hard_version_big") }}
                      </div>
                      <div>
                        <el-input
                          v-model.trim="
                            generateEquipmentIdForm.hardware_large_version
                          "
                          :placeholder="$t('yudata.versionTip_big')"
                          auto-complete="off"
                          clearable
                        />
                      </div>
                    </el-form-item>
                    <el-form-item prop="hardware_small_version" style="flex: 1">
                      <div class="label" style="text-align: left">
                        {{ $t("yudata.hard_version_samll") }}
                      </div>
                      <div>
                        <el-input
                          v-model.trim="
                            generateEquipmentIdForm.hardware_small_version
                          "
                          :placeholder="$t('yudata.versionTip_small')"
                          auto-complete="off"
                          clearable
                        />
                      </div>
                    </el-form-item>
                  </div>
                </el-form>

                <span slot="footer">
                  <el-button
                    type="info"
                    round
                    size="default"
                    @click="generateDialogVisible = false"
                    >{{ $t("AddMachine.Cancel") }}</el-button
                  >
                  <el-button
                    type="primary"
                    round
                    size="default"
                    @click="handleSubmitGenerateDeviceId"
                    >{{ $t("Login.OK") }}</el-button
                  >
                </span>
              </el-dialog>
            </div>
          </el-form-item>
          <el-form-item prop="device_module">
            <div class="label" style="text-align: left">
              设备支持的模块（多选）
              <el-checkbox
                class="custom_checkbox"
                v-model="equipmenntIdForm.wifi"
                :true-label="1"
                :false-label="0"
                >WiFi</el-checkbox
              >
              <el-checkbox
                class="custom_checkbox"
                v-model="equipmenntIdForm.fourth"
                :true-label="1"
                :false-label="0"
                >4G</el-checkbox
              >
            </div>
          </el-form-item>
          <el-form-item>
            <div class="label" style="text-align: left">
              云端服务
              <el-checkbox
                class="custom_checkbox"
                v-model="equipmenntIdForm.auth_status"
                :true-label="1"
                :false-label="0"
              ></el-checkbox>
            </div>
          </el-form-item>
          <el-form-item prop="'remark'">
            <div class="label" style="text-align: left">
              {{ $t("yudata.remark") }}
            </div>
            <div style="display: flex; gap: 20px">
              <el-input
                v-model="equipmenntIdForm.remark"
                :placeholder="$t('yudata.input_remark')"
                auto-complete="off"
                clearable
              />
              <el-button
                style="visibility: hidden"
                type="primary"
                round
                @click="handleOpenCustomDialog"
                >{{ $t("yudata.auto_generate") }}</el-button
              >
            </div>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer">
        <el-button type="info" round @click="dialogVisible = false">{{
          $t("AddMachine.Cancel")
        }}</el-button>
        <el-button type="primary" round @click="handleSubmitDeviceIdForm">{{
          $t("Login.OK")
        }}</el-button>
      </span>
    </el-dialog>

    <!-- 删除确认密码弹框 -->
    <el-dialog
      :title="$t('Custom.AdPower')"
      :visible.sync="dialogVisibles"
      width="364px"
      class="dialog dialogDelete"
    >
      <h3 style="text-align: left; margin: 0">
        {{ deleteForm.currentCompany }}
      </h3>
      <el-form
        ref="deleteFormRef"
        label-width="0px"
        :model="deleteForm"
        :rules="deleteFormRules"
      >
        <div class="form-box">
          <el-form-item prop="password">
            <div class="label" style="text-align: left">
              {{ $t("Custom.AdPwd") }}
            </div>
            <el-input
              v-model.trim="deleteForm.password"
              :placeholder="$t('Custom.pwd')"
              auto-complete="off"
              type="password"
              show-password
              clearable
            />
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button round type="info" @click="dialogVisibles = false">{{
          $t("AddMachine.Cancel")
        }}</el-button>
        <!-- 输入密码 加一个enter快捷键 -->
        <el-button round type="primary" @click="handleSubmitDeleteForm">{{
          $t("Login.OK")
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="$t('Machine.Edit')"
      :visible.sync="editDialogVisible"
      width="728px"
      class="dialog dialogDelete"
    >
      <h3 style="text-align: left; margin: 0">
        {{ deleteForm.currentCompany }}
      </h3>
      <el-form
        ref="editFormRef"
        label-width="0px"
        :model="editForm"
        :rules="editFormRules"
      >
        <div class="form-box">
          <el-form-item prop="device_module">
            <div class="label" style="text-align: left">
              设备支持的模块（多选）
            </div>
            <el-row :gutter="20">
              <el-col :span="3"
                ><el-checkbox
                  class="custom_checkbox"
                  v-model="editForm.wifi"
                  :true-label="1"
                  :false-label="0"
                  >WiFi</el-checkbox
                ></el-col
              >
              <el-col :span="3">
                <el-checkbox
                  class="custom_checkbox"
                  v-model="editForm.fourth"
                  :true-label="1"
                  :false-label="0"
                  >4G</el-checkbox
                >
              </el-col>
              <el-col :span="6" style="float: right">
                <el-button round type="primary" @click="handleGenerateMQTT">
                  重新生成MQTT
                </el-button>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item>
            <div class="label" style="text-align: left">
              {{ $t("yudata.remark") }}
            </div>
            <el-input
              v-model="editForm.remark"
              :placeholder="$t('yudata.input_remark')"
              type="textarea"
              :rows="3"
              clearable
            />
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button round type="info" @click="editDialogVisible = false">{{
          $t("AddMachine.Cancel")
        }}</el-button>
        <!-- 输入密码 加一个enter快捷键 -->
        <el-button round type="primary" @click="handleConfirmEdit">{{
          $t("Login.OK")
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="$t('Login.Account')"
      :visible.sync="convertDialogVisible"
      width="364px"
      class="dialog"
    >
      <!-- <div class="form-box" style="text-align: left; margin-bottom: 10px">
        {{ convertFormData.account }}
      </div> -->
      <el-form
        ref="convertFromRef"
        :model="convertFormData"
        label-width="0px"
        :rules="convertRules"
      >
        <el-form-item>
          <div class="label" style="text-align: left; font-size: 18px">
            {{ $t("UserInfo.Account") }}
          </div>
          <el-input v-model.trim="convertFormData.account" readonly />
        </el-form-item>
        <el-form-item prop="password">
          <div class="label" style="text-align: left; font-size: 18px">
            {{ $t("Login.Password") }}
          </div>
          <el-input
            v-model.trim="convertFormData.password"
            type="password"
            :placeholder="$t('Login.inputPwd')"
            autocomplete="new-passowrd"
            clearable
            showPassword
          />
        </el-form-item>
        <el-form-item prop="customer_id">
          <div class="label" style="text-align: left; font-size: 18px">
            {{ $t("Custom.Custom") }}
          </div>
          <el-select
            v-model="convertFormData.customer_id"
            :placeholder="$t('Custom.SelectCustom')"
            clearable
            style="width: 100%"
            :loading="getCustomLoading"
          >
            <el-option
              v-for="item in customOptions"
              :label="item.text"
              :value="item.value"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <div
          class="btn-normal pan-btn blue-btn bts"
          style="margin-right: 0px; background: #4875ef"
          @click="handleSubmitConvertForm"
        >
          {{ $t("Login.OK") }}
        </div>
        <div
          class="btn-normal pan-btn tiffany-btn bts"
          style="background: #767e95"
          @click="convertDialogVisible = false"
        >
          {{ $t("AddMachine.Cancel") }}
        </div>
      </span>
    </el-dialog>

    <!-- 批量生成弹窗 -->
    <el-dialog
      class="dialog"
      width="604px"
      :title="$t('yudata.generateEquipId')"
      :visible.sync="batchAddDialogVisible"
      append-to-body
      :close-on-click-modal="false"
    >
      <el-form
        ref="batchAddFormRef"
        :model="batchAddForm"
        label-width="0px"
        :rules="batchAddFormRules"
        v-loading="batchAddLoading"
      >
        <template v-if="batchAddStep == 1">
          <el-form-item prop="num">
            <div class="label" style="text-align: left">创建ID数量</div>
            <div>
              <el-input
                style="width: 100%"
                v-model="batchAddForm.num"
                placeholder="请输入创建ID数量"
                type="number"
              >
              </el-input>
            </div>
          </el-form-item>
          <el-form-item prop="customer_id">
            <div class="label" style="text-align: left">
              {{ $t("yudata.company") }}
            </div>
            <div>
              <el-select
                style="width: 100%"
                v-model="batchAddForm.customer_id"
                :placeholder="$t('yudata.companyTip')"
                clearable
              >
                <el-option
                  v-for="item in companyOptions"
                  :key="item.text"
                  :label="item.text"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item prop="order_type">
            <div class="label" style="text-align: left">
              {{ $t("yudata.order_type") }}
            </div>
            <div>
              <el-select
                style="width: 100%"
                v-model="batchAddForm.order_type"
                :placeholder="$t('yudata.orderTip')"
                clearable
              >
                <el-option
                  v-for="item in orderTypeOptions"
                  :key="item.text"
                  :label="item.text"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item prop="erp_type">
            <div class="label" style="text-align: left">
              {{ $t("yudata.erp_type") }}
            </div>
            <div>
              <el-select
                style="width: 100%"
                v-model="batchAddForm.erp_type"
                :placeholder="$t('yudata.erpTip')"
                clearable
              >
                <el-option
                  v-for="item in erpTypeOptions"
                  :key="item.text"
                  :label="item.text"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item prop="device_type">
            <div class="label" style="text-align: left">
              {{ $t("Setting.EquipmentType") }}
            </div>
            <div>
              <el-select
                style="width: 100%"
                v-model="batchAddForm.device_type"
                :placeholder="$t('yudata.equipTip')"
                clearable
              >
                <el-option
                  v-for="item in deviceOptions"
                  :key="item.text"
                  :label="item.text"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <div style="display: flex; gap: 20px">
            <el-form-item prop="hardware_large_version" style="flex: 1">
              <div class="label" style="text-align: left">
                {{ $t("yudata.hard_version_big") }}
              </div>
              <div>
                <el-input
                  v-model.trim="batchAddForm.hardware_large_version"
                  :placeholder="$t('yudata.versionTip_big')"
                  auto-complete="off"
                  clearable
                />
              </div>
            </el-form-item>
            <el-form-item prop="hardware_small_version" style="flex: 1">
              <div class="label" style="text-align: left">
                {{ $t("yudata.hard_version_samll") }}
              </div>
              <div>
                <el-input
                  v-model.trim="batchAddForm.hardware_small_version"
                  :placeholder="$t('yudata.versionTip_small')"
                  auto-complete="off"
                  clearable
                />
              </div>
            </el-form-item>
          </div>
        </template>
        <template v-else>
          <el-form-item prop="device_module">
            <div class="label" style="text-align: left">
              设备支持的模块（多选）
              <el-checkbox
                class="custom_checkbox"
                v-model="batchAddForm.wifi"
                :true-label="1"
                :false-label="0"
                >WiFi</el-checkbox
              >
              <el-checkbox
                class="custom_checkbox"
                v-model="batchAddForm.fourth"
                :true-label="1"
                :false-label="0"
                >4G</el-checkbox
              >
            </div>
          </el-form-item>
          <el-form-item>
            <div class="label" style="text-align: left">
              云端服务
              <el-checkbox
                class="custom_checkbox"
                v-model="batchAddForm.auth_status"
                :true-label="1"
                :false-label="0"
              ></el-checkbox>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="label" style="text-align: left">
              {{ $t("yudata.remark") }}
            </div>
            <el-input
              v-model="batchAddForm.remark"
              :placeholder="$t('yudata.input_remark')"
              auto-complete="off"
              clearable
            />
          </el-form-item>
        </template>
      </el-form>

      <span slot="footer">
        <template v-if="batchAddStep == 1">
          <el-button
            type="info"
            round
            size="default"
            @click="handleCancelBatchAdd"
            >{{ $t("AddMachine.Cancel") }}</el-button
          >
          <el-button
            type="primary"
            round
            size="default"
            @click="handleNextBatchAdd"
            >下一步</el-button
          >
        </template>
        <template v-else>
          <el-button
            type="info"
            round
            size="default"
            @click="handlePrevBatchAdd"
            >上一步</el-button
          >
          <el-button
            type="primary"
            round
            size="default"
            @click="handleSubmitBatchAdd"
            >{{ $t("Login.OK") }}</el-button
          >
        </template>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import "../../style/table.less";
import {
  getCustomer,
  getGenerateOptionList,
  generateDeviceId,
  deleteDeviceId,
  adddevice,
  editRemark,
  convertEquipment,
  getDevices,
  exportDevices,
  auth,
  reGenerateMqtt,
  batchAdd,
} from "@/api/custom";
import dayjs from "dayjs";
import i18n from "../../lang";
import {
  ref,
  getCurrentInstance,
  onMounted,
  reactive,
  nextTick,
  h,
  unref,
} from "vue";
import {
  awaitWraper,
  isEmpty,
  clearForm,
  showMessage,
  isEmptyObj,
  showModal,
} from "../../js/util";
import Constant from "../../js/constant";
import { useClipboard, usePermission } from "@vueuse/core";

export default {
  setup() {
    const { proxy: that } = getCurrentInstance();
    const searchFlag = ref(false);
    const handleSearch = async () => {
      page.value = 1;
      await getCustomerList();
      if (isEmptyObj(searchForm)) {
        expandRowKeys.value = [];
        searchFlag.value = false;
      } else {
        searchFlag.value = true;
        expandRowKeys.value = [tableData.value[0]?.id];
        tableData.value.forEach((tableItem) => {
          if (tableItem.devices?.length) {
            tableItem.devices = tableItem.devices.map((item) => ({
              ...item,
              passwordVisible: false,
            }));
          }
        });
      }
    };
    // 用于新增、编辑、删除
    const handleUpdateTable = async (page) => {
      if (isEmptyObj(searchForm)) {
        // 这种情况下  肯定是展开的
        const targetIdx = tableData.value.findIndex(
          (item) => item.id == expandRowKeys.value[0]
        );
        if (targetIdx >= 0) {
          if (page) {
            tableData.value[targetIdx].page = page;
          }
          getDeviceList(tableData.value[targetIdx]);
        }
      } else {
        getCustomerList();
        searchFlag.value = true;
        expandRowKeys.value = [tableData.value[0]?.id];
      }
    };
    const handleReset = () => {
      for (let key of Object.keys(searchForm)) {
        searchForm[key] = "";
      }
      searchFlag.value = false;
      handleSearch();
    };

    // 处理时间格式
    const handleTime = (time) => {
      if (isEmpty(time)) return "--";
      return dayjs(time * 1000).format("YYYY-MM-DD HH:mm");
    };

    // 生成设备id dialog 相关逻辑
    const generateDialogVisible = ref(false);
    const generateDialogLoading = ref(false);
    const handleOpenCustomDialog = async () => {
      generateDialogVisible.value = true;
      generateDialogLoading.value = true;
      // 清除上一次表单数据
      clearForm(generateEquipmentIdForm, ["customer_id"]);

      // 清除校验结果
      nextTick(() => {
        autoGenerateRef.value.clearValidate?.();
      });
      const [[generateError, _], [customerDataError, customerDataRes]] =
        await Promise.all([
          awaitWraper(getGenerateOptions()),
          awaitWraper(getCustomer({ all: "1" })),
        ]);
      generateDialogLoading.value = false;
      if (generateError || customerDataError) return;
      companyOptions.value = customerDataRes.data.data.map((item) => {
        return {
          text: item.company,
          value: item.id,
        };
      });
      //设置一些默认值
      // device_type  1631  erp_type self
      if (deviceOptions.value.find((item) => item.value == 1631)) {
        generateEquipmentIdForm.device_type = "1631";
      } else {
        generateEquipmentIdForm.device_type = deviceOptions.value?.[0].value;
      }
      if (erpTypeOptions.value.find((item) => item.value == "self")) {
        generateEquipmentIdForm.erp_type = "self";
      } else {
        generateEquipmentIdForm.erp_type = erpTypeOptions.value?.[0].value;
      }
      for (let key of Object.keys(initVal)) {
        generateEquipmentIdForm[key] = initVal[key];
      }
    };

    const tableData = ref(null);
    const expandRowKeys = ref([]);
    const expandChange = (row, rowList) => {
      rowClick(row);
    };
    const searchForm = reactive({
      number: "",
      serial_number: "",
    });
    // 获取列表接口
    const getCustomerList = (val, type, item) => {
      const loading = that.$loading({
        lock: true,
        text: that.$t("Tip.Loading"),
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      return getCustomer({
        page: page.value,
        pagesize: pagesize.value,
        ...searchForm,
      })
        .then((res) => {
          tableData.value = res.data.data.map((item) => {
            return {
              ...item,
              pagesize: 10,
              page: 1,
              total: 0,
              loading: false,
            };
          });

          total.value = res.data.total;
        })
        .finally(() => {
          loading.close();
        });
    };

    const getDeviceList = (row) => {
      row.loading = true;
      // that.$set(row, "devices", []);
      return getDevices({
        page: row.page,
        pagesize: row.pagesize,
        customer_id: row.id,
      })
        .then(({ data }) => {
          const newData = data.data.map((item) => {
            item.passwordVisible = false;
            return item;
          });
          that.$set(row, "devices", newData);
          that.$set(row, "total", data.total);
        })
        .catch((err) => [])
        .finally(() => {
          row.loading = false;
        });
    };
    const handleSecondSizeChange = (val, row) => {
      row.pagesize = val;
      row.page = 1;
      getDeviceList(row);
    };
    const handleSecondPageChange = (val, row) => {
      row.page = val;
      getDeviceList(row);
    };
    onMounted(() => {
      getCustomerList("", "type");
    });

    //
    const total = ref(0);
    const page = ref(1);
    const pagesize = ref(15);
    const handleSizeChange = (val) => {
      page.value = 1;
      pagesize.value = val;
      that.getCustomerList("", "type");
    };
    const handleCurrentChange = (val) => {
      page.value = val;
      that.getCustomerList("", "type");
    };
    const dialogVisible = ref(false);
    // 打开新增设备id 弹窗
    const handleAddEquipId = (row) => {
      dialogVisible.value = true;
      clearForm(equipmenntIdForm, ["customer_id"], {
        fourth: 0,
        wifi: 0,
        auth_status: 0,
      });
      // 清除校验
      nextTick(() => {
        equipmenntIdFormRef.value.clearValidate();
      });
      equipmenntIdForm.customer_id = generateEquipmentIdForm.customer_id =
        row.id;
    };

    // 导出
    const handleExport = (row) => {
      const url = exportDevices({
        page: row.page,
        pagesize: row.pagesize,
        customer_id: row.id,
        export: true,
        Authorization: localStorage.getItem("token"),
      });
      var hiddenIFrameID = "hiddenDownloader";
      var iframe = document.createElement("iframe");
      iframe.id = hiddenIFrameID;
      iframe.style.display = "none";
      document.body.appendChild(iframe);
      iframe.src = url; //直接下载，不会弹出
    };

    //添加设备id 表单
    const equipmenntIdForm = reactive({
      number: "",
      remark: "",
      customer_id: "", //
      fourth: 1,
      wifi: 1,
      auth_status: 0, //云端服务授权
    });
    const equipmenntIdFormRules = {
      number: [
        {
          required: true,
          trigger: "blur",
          validator: function (rule, value, callback) {
            // 必填
            if (!value) {
              callback(new Error(i18n.t("yudata.input_deviceid")));
            } else {
              callback();
            }
          },
        },
      ],
      device_module: [
        {
          required: true,
          trigger: ["change", "blur"],
          validator: function (rule, value, callback) {
            // 必填
            if (!equipmenntIdForm.fourth && !equipmenntIdForm.wifi) {
              callback(new Error("请选择至少一个设备支持的模块"));
            } else {
              callback();
            }
          },
        },
      ],
      remark: "",
    };
    // 生成设备id 表单
    const generateEquipmentIdForm = reactive({
      order_type: "", //订单类型
      erp_type: "", //erp 类型
      device_type: "", //设备类型
      hardware_large_version: "", // 硬件大版本号
      hardware_small_version: "", // 硬件小版本号
      customer_id: "", //客户id
    });
    // 表单初始值
    const initVal = {
      hardware_large_version: "01", // 硬件大版本号
      hardware_small_version: "01", // 硬件小版本号
    };
    const autoGenerateRef = ref(null);
    const generateEquipmentIdFormRules = {
      customer_id: [
        {
          required: true,
          trigger: ["blur"],
          message: i18n.t("yudata.companyTip"),
        },
      ],
      order_type: [
        {
          required: true,
          trigger: ["blur"],
          message: i18n.t("yudata.orderTip"),
        },
      ],
      erp_type: [
        {
          required: true,
          trigger: ["blur"],
          message: i18n.t("yudata.erpTip"),
        },
      ],
      device_type: [
        {
          required: true,
          trigger: ["blur"],
          message: i18n.t("yudata.equipTip"),
        },
      ],

      hardware_large_version: [
        {
          required: true,
          trigger: "blur",
          validator: function (rule, value, callback) {
            // 必填
            if (!value) {
              callback(new Error(i18n.t("yudata.big_tip")));
            } else if (!Constant.numberTwo.test(value)) {
              callback(new Error(i18n.t("yudata.number_reg")));
            } else {
              callback();
            }
          },
        },
      ],
      hardware_small_version: [
        {
          required: true,
          trigger: "blur",
          validator: function (rule, value, callback) {
            // 必填
            if (!value) {
              callback(new Error(i18n.t("yudata.small_tip")));
            } else if (!Constant.numberTwo.test(value)) {
              callback(new Error(i18n.t("yudata.number_reg")));
            } else {
              callback();
            }
          },
        },
      ],
    };
    // 公司列表
    const companyOptions = ref([]);
    // 订单类型选择列表
    const orderTypeOptions = ref([]);
    //erp类型选择列表
    const erpTypeOptions = ref([]);
    // 设备列表
    const deviceOptions = ref([]);
    const getGenerateOptions = async () => {
      const [err, res] = await awaitWraper(getGenerateOptionList());
      if (err) return;
      const { order_type = [], erp_type = [], device_type = [] } = res.data;
      orderTypeOptions.value = order_type;
      erpTypeOptions.value = erp_type;
      deviceOptions.value = device_type;
      return [err, res];
    };
    // "01120230600011631936333333"
    const handleSubmitGenerateDeviceId = () => {
      autoGenerateRef.value.validate(async (valid) => {
        if (valid) {
          // 提交
          const [err, res] = await awaitWraper(
            generateDeviceId(generateEquipmentIdForm)
          );
          if (err) return;
          generateDialogVisible.value = false;
          equipmenntIdForm.number = res.data.number;
        }
      });
    };
    const equipmenntIdFormRef = ref(null);
    const handleSubmitDeviceIdForm = () => {
      equipmenntIdFormRef.value.validate(async (valid) => {
        if (valid) {
          // 提交
          const [err, res] = await awaitWraper(adddevice(equipmenntIdForm));
          if (err) return;
          dialogVisible.value = false;
          showMessage(res.message);
          handleUpdateTable(1);
          // 保持展开
        }
      });
    };
    const rowClick = async (row, column, event) => {
      if (event?.target.dataset?.value == "operate") return;
      if (expandRowKeys.value.includes(row.id)) {
        expandRowKeys.value = [];
      } else {
        expandRowKeys.value = [row.id];
        !searchFlag.value && getDeviceList(row);
      }
    };
    const passwordHide = new Array(10).fill("●").join("");
    // 处理密码
    const handlePasswordStatus = ({ passwordVisible, password }) => {
      if (isEmpty(password)) {
        return "--";
      }
      if (passwordVisible) {
        return password;
      }
      // return new Array(number.length).fill("●").join("");
      return passwordHide;
    };

    //
    const handlePointerDown = (e, row) => {
      row.passwordVisible = true;
    };

    const handleCancelPointerDown = (e, row) => {
      row.passwordVisible = false;
    };
    const togglePasswordStatus = (row) => {
      row.passwordVisible = !row.passwordVisible;
    };
    const deleteForm = reactive({
      password: "",
      id: "",
      currentCompany: "",
    });
    const deleteFormRules = {
      password: [
        {
          required: true,
          validator: function (rule, value, callback) {
            // 必填
            if (!value) {
              callback(new Error(i18n.t("Setting.IpPwd")));
            } else if (!Constant.password_reg.test(value)) {
              callback(new Error(i18n.t("yudata.password_reg")));
            } else {
              callback();
            }
          },
          trigger: ["blur"],
        },
      ],
    };

    const editFormRules = {
      device_module: [
        {
          required: true,
          trigger: ["change", "blur"],
          validator: function (rule, value, callback) {
            // 必填
            if (!editForm.fourth && !editForm.wifi) {
              callback(new Error("请选择至少一个设备支持的模块"));
            } else {
              callback();
            }
          },
        },
      ],
    };
    const deleteFormRef = ref(null);
    const dialogVisibles = ref(false);
    const resetDeleteForm = () => {
      for (const key of Object.keys(deleteForm)) {
        deleteForm[key] = "";
      }
    };
    // 删除
    const handleOpenDeleteConfirmDialog = ({ id }, { row: { company } }) => {
      // 每次都要清空输入密码表单
      resetDeleteForm();
      dialogVisibles.value = true;
      nextTick(() => {
        deleteFormRef.value.clearValidate?.();
      });
      deleteForm.currentCompany = company;
      deleteForm.id = id;
    };

    const handleSubmitDeleteForm = () => {
      // 删除前输入管理员密码
      // 点确定 调用删除接口
      // 未输入密码 提示输入密码
      deleteFormRef.value.validate(async (valid) => {
        if (valid) {
          const newPassword = that.$md5(deleteForm.password);
          const { id } = deleteForm;
          const [err, res] = await awaitWraper(
            deleteDeviceId({ id, password: newPassword })
          );
          if (err) return;
          showMessage(res.message);
          dialogVisibles.value = false;
          handleUpdateTable(1);
        } else {
        }
        return false;
      });
    };

    const { text, isSupported, copy } = useClipboard();
    const permissionWrite = usePermission("clipboard-write");
    const permissionRead = usePermission("clipboard-read");
    const handleCopy = (val) => {
      if (permissionRead.value == "denied") {
        return showMessage("没有复制权限", "error");
      }
      if (permissionWrite.value == "denied") {
        return showMessage("没有粘贴权限", "error");
      }
      copy(val).then(() => {
        showMessage("复制成功");
      });
    };

    const editDialogVisible = ref(false);
    const editForm = reactive({ number: "", remark: "", fourth: "", wifi: "" });
    // 重新生产Mqtt
    const handleGenerateMQTT = () => {
      // console.log("重新生成mqtt");

      showModal(
        "注意：重新生成MQTT账号与密码后，此设备之前所使用的MQTT账号与密码将不再生效，您需要重新为设备绑定新的MQTT资料，确定生成？",
        void 0,
        "提示",
        "default2_message_box"
      ).then(() => {
        // 吊用接口
        // 关闭挡枪的弹窗
        reGenerateMqtt({ number: editForm.number })
          .then(() => {
            showMessage("生成成功");
            editDialogVisible.value = false;
            // 重新获取数据
            handleUpdateTable();
          })
          .catch(() => {
            showMessage("生成失败", "error");
          });
      });
    };

    const editFormRef = ref();
    const handleEditRemark = (row, parentData) => {
      editDialogVisible.value = true;
      Object.keys(editForm).forEach((item) => {
        editForm[item] = "";
        editForm[item] = row[item] ?? "";
      });
    };

    const handleConfirmEdit = () => {
      editFormRef.value.validate(async (valid) => {
        if (valid) {
          editRemark(editForm)
            .then((res) => {
              editDialogVisible.value = false;
              showMessage("修改成功");
              handleUpdateTable();
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    };
    const convertFormData = reactive({
      account: localStorage.getItem("account"),
      password: "",
      number: "",
      customer_id: "", //转移目标客户id
    });
    const convertDialogVisible = ref(false);
    const convertFromRef = ref();
    const convertRules = reactive({
      password: [
        {
          required: true,
          message: that.$t("Login.inputPwd"),
          trigger: "blur",
        },
      ],
      customer_id: [
        {
          required: true,
          message: that.$t("Custom.SelectCustom"),
          trigger: "change",
        },
      ],
    });
    const customOptions = ref([]);
    const getCustomLoading = ref(false);
    const handleConvert = async (row, customer_id) => {
      convertDialogVisible.value = true;
      nextTick(() => {
        convertFromRef.value.resetFields();
      });

      convertFormData.number = row.number;
      // loding
      getCustomLoading.value = true;
      const [err, res] = await awaitWraper(getCustomer({ all: "1" }));
      getCustomLoading.value = false;
      if (err) return;
      customOptions.value = res.data.data
        .filter((item) => item.id != customer_id)
        .map((item) => {
          return {
            text: item.company,
            value: item.id,
          };
        });
    };
    const handleSubmitConvertForm = () => {
      convertFromRef.value.validate((valid) => {
        if (valid) {
          // alert("submit!");
          convertEquipment({
            ...convertFormData,
            ...{ password: that.$md5(convertFormData.password) },
          }).then((res) => {
            showMessage(that.$t("Custom.convertSuccess"));
            convertDialogVisible.value = false;
            page.value = 1;
            handleUpdateTable(1);
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    };
    const waysConfig = {
      wifi: "WiFi",
      fourth: "4G",
    };
    const getConnectWays = (row) => {
      return ["wifi", "fourth"]
        .filter((item) => row[item])
        .map((item) => waysConfig[item])
        .join("、");
    };

    // 云端服务授权
    const handleAuth = (row, auth_status) => {
      auth({
        number: row.number,
        auth_status,
      })
        .then(() => {
          showMessage("修改成功");
        })
        .catch(() => {
          row.auth_status = Number(!Number(auth_status));
        });
    };

    const batchAddDialogVisible = ref(false);
    const batchAddForm = reactive({
      num: "",
      order_type: "", //订单类型
      erp_type: "", //erp 类型
      device_type: "", //设备类型
      hardware_large_version: "", // 硬件大版本号
      hardware_small_version: "", // 硬件小版本号
      customer_id: "", //客户id
      wifi: 0,
      fourth: 0,
      auth_status: 0, //云端服务授权
      remark: "",
    });

    const batchAddFormRef = ref();
    const batchAddFormRules = reactive({
      num: [
        {
          required: true,
          message: "请输入创建ID数量",
          trigger: "blur",
        },
        {
          pattern: /^[1-9]\d*$/,
          message: "请输入正整数",
          trigger: "blur",
        },
      ],
      customer_id: [
        {
          required: true,
          message: "请选择公司",
          trigger: "change",
        },
      ],
      order_type: [
        {
          required: true,
          message: "请选择订单类型",
          trigger: "change",
        },
      ],
      erp_type: [
        {
          required: true,
          message: "请选择erp类型",
          trigger: "change",
        },
      ],
      device_type: [
        {
          required: true,
          message: "请选择设备类型",
          trigger: "change",
        },
      ],
    });

    const batchAddStep = ref(1);
    const batchAddLoading = ref(false);
    const handleNextBatchAdd = () => {
      unref(batchAddFormRef).validate(async (valid) => {
        if (valid) {
          batchAddStep.value = 2;
        }
      });
    };
    const handlePrevBatchAdd = () => {
      batchAddStep.value = 1;
    };
    const handleCancelBatchAdd = () => {
      batchAddDialogVisible.value = false;
      batchAddStep.value = 1;
    };
    const handleBatchAdd = async () => {
      batchAddDialogVisible.value = true;
      batchAddLoading.value = true;
      batchAddStep.value = 1;
      clearForm(batchAddForm, void 0, {
        fourth: 0,
        wifi: 0,
        auth_status: 0,
      });
      nextTick(() => {
        batchAddFormRef.value?.clearValidate?.();
      });
      const [[generateError, _], [customerDataError, customerDataRes]] =
        await Promise.all([
          awaitWraper(getGenerateOptions()),
          awaitWraper(getCustomer({ all: "1" })),
        ]);
      batchAddLoading.value = false;
      if (generateError || customerDataError) return;
      companyOptions.value = customerDataRes.data.data.map((item) => {
        return {
          text: item.company,
          value: item.id,
        };
      });
      for (let key of Object.keys(initVal)) {
        batchAddForm[key] = initVal[key];
      }
    };

    const handleSubmitBatchAdd = () => {
      batchAddFormRef.value.validate(async (valid) => {
        if (valid) {
          // 提交
          const [err, res] = await awaitWraper(
            batchAdd({
              ...batchAddForm,
              num: Number(batchAddForm.num),
            })
          );
          if (err) return;
          batchAddDialogVisible.value = false;
          showMessage("新增成功");
          handleUpdateTable(1);
        }
      });
    };
    return {
      searchForm,
      generateDialogVisible,
      tableData,
      expandRowKeys,
      dialogVisible,
      total,
      page,
      pagesize,
      equipmenntIdForm,
      equipmenntIdFormRules,
      generateEquipmentIdForm,
      companyOptions,
      erpTypeOptions,
      orderTypeOptions,
      deviceOptions,
      generateEquipmentIdFormRules,
      autoGenerateRef,
      generateDialogLoading,
      equipmenntIdFormRef,
      convertFormData,
      convertDialogVisible,
      convertFromRef,
      convertRules,
      customOptions,
      getCustomLoading,
      handleConvert,
      handleSubmitConvertForm,
      searchFlag,
      handleSearch,
      handleReset,
      handleTime,
      handleOpenCustomDialog,
      expandChange,
      getCustomerList,
      handleAddEquipId,
      handleExport,
      handleOpenDeleteConfirmDialog,
      handleSizeChange,
      handleCurrentChange,
      rowClick,
      handlePasswordStatus,
      handlePointerDown,
      handleCancelPointerDown,
      handleSubmitGenerateDeviceId,
      handleSubmitDeviceIdForm,
      deleteForm,
      dialogVisibles,
      deleteFormRules,
      deleteFormRef,
      handleSubmitDeleteForm,
      togglePasswordStatus,
      handleCopy,
      handleEditRemark,
      editDialogVisible,
      editForm,
      editFormRef,
      editFormRules,
      handleConfirmEdit,
      handleSecondSizeChange,
      handleSecondPageChange,
      getConnectWays,
      handleAuth,
      handleGenerateMQTT,
      batchAddDialogVisible,
      batchAddForm,
      batchAddFormRules,
      handleBatchAdd,
      batchAddFormRef,
      batchAddStep,
      handleSubmitBatchAdd,
      handleCancelBatchAdd,
      handleNextBatchAdd,
      handlePrevBatchAdd,
      batchAddLoading,
    };
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";

.opearate_imgwrapper {
  display: flex;
  justify-content: space-around;

  img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}

.search_wrapper {
  display: flex;
  padding: 10px 25px;
  gap: 20px;

  .search_item {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 18px;
  }
}

.custom_checkbox {
  /deep/.el-checkbox__inner {
    width: 18px;
    height: 18px;
    background: #9a9b9b;
    border-color: #9a9b9b;
    &::after {
      width: 6px;
      height: 12px;
      border-width: 2px;
      top: -2px;
    }
  }
}
</style>
